<template>
  <div>
    <MathValidation v-if="!is_human" :onSuccess="handleValidationSuccess" />
    <div v-else>
      <div id="loader" v-if="!is_failed"></div>
      <div v-if="is_failed">
        <div class="container">
          <h1>Erro 404</h1>
          <p>A página que você está procurando não foi encontrada.</p>
          <p>Por favor, verifique o URL e tente novamente.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MathValidation from "./MathValidation.vue";
import axios from "axios";
import { BASE_URL } from "../../global";

export default {
  name: "HomeView",
  components: { MathValidation },
  data() {
    return {
      is_failed: false,
      location: { latitude: 0, longitude: 0 },
      token: "",
      is_human: false, // Flag para indicar validação
    };
  },

  methods: {
    handleValidationSuccess() {
      this.is_human = true; // Validação bem-sucedida
      this.fetchData(); // Realiza as ações após validação
    },

    async fetchData() {
      const token = this.$route.params.token;
      this.token = token;
      let deviceModel = null;

      await axios
        .get(`${BASE_URL}/shorten_data/?token=${token}`)
        .then(async (response) => {
          if (response.data) {
            const { _value } = response.data;

            if (_value.is_check_location) {
              await this.getLocation();
            }

            if (_value.is_check_disp) {
              deviceModel = this.getDeviceModel();
            }

            if (this.is_human) {
              // Fazer um post com as informações
              await axios
                .post(`${BASE_URL}/shorten_data/`, {
                  token: this.token,
                  location: {
                    latitude: this.location.latitude,
                    longitude: this.location.longitude,
                  },
                  device: deviceModel,
                  qrcode: _value.qrcode ? true : false,
                  id: _value.id ? _value.id : null,
                })
                .catch(() => {
                  console.error(
                    "Erro ao enviar dados de localização e dispositivo."
                  );
                })
                .finally(() => {
                  if (_value.url) {
                    window.location.href = _value.url;
                  }
                });
            }
          }
        })
        .catch(() => {
          this.is_failed = true;
        });
    },

    async getLocation() {
      await navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "granted") {
            return this.getCoordinates();
          }

          return navigator.geolocation.getCurrentPosition(
            (position) => {
              this.location = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              };
            },
            (error) => {
              console.error("Erro ao obter localização:", error);
            }
          );
        })
        .catch(() => {
          console.log("Permissão de localização negada.");
        });
    },

    async getCoordinates() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.location.latitude = position.coords.latitude;
          this.location.longitude = position.coords.longitude;
        },
        (error) => {
          console.error("Erro ao obter localização:", error);
        }
      );
    },

    getDeviceModel() {
      const userAgent = navigator.userAgent.toLowerCase(); // Padronizar o user-agent em minúsculas
      let deviceType = "Desconhecido";

      if (userAgent.includes("iphone")) {
        deviceType = "iPhone";
      } else if (userAgent.includes("ipad")) {
        deviceType = "iPad";
      } else if (userAgent.includes("android")) {
        if (userAgent.includes("mobile")) {
          deviceType = "Android (Smartphone)";
        } else {
          deviceType = "Android (Tablet)";
        }
      } else if (userAgent.includes("windows")) {
        if (userAgent.includes("phone")) {
          deviceType = "Windows Phone";
        } else {
          deviceType = "Computador Windows";
        }
      } else if (
        userAgent.includes("macintosh") ||
        userAgent.includes("mac os")
      ) {
        deviceType = "Computador Mac";
      } else if (userAgent.includes("linux")) {
        if (userAgent.includes("mobile")) {
          deviceType = "Outro Dispositivo Móvel (Linux)";
        } else {
          deviceType = "Computador Linux";
        }
      } else if (userAgent.includes("mobile")) {
        deviceType = "Outro Dispositivo Móvel";
      } else {
        deviceType = "Computador ou Dispositivo Não Identificado";
      }

      return deviceType;
    },
  },

  mounted() {
    // Nada acontece até que a validação seja concluída
    this.fetchData(); // Realiza as ações após validação
  },
};
</script>

<style scoped>
/* Estilos permanecem os mesmos */
</style>

<style scoped>
/* Center the loader */
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.container {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-size: 48px;
  color: #ff6347;
}

p {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}
</style>
