<template>
  <div class="math-validation">
    <div class="validation-container">
      <h1>Validação de Segurança</h1>
      <p>Resolva a equação abaixo para confirmar que você é humano:</p>
      <div class="equation">
        <span>{{ num1 }} + {{ num2 }} = </span>
        <div style="margin-left: 5%">
          <input v-model="userAnswer" placeholder="?" class="answer-input" />
        </div>
      </div>
      <button class="validate-button" @click="validateAnswer">Validar</button>
      <p v-if="errorMessage" class="error" id="error">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MathValidation",
  props: {
    onSuccess: Function,
  },
  data() {
    return {
      num1: Math.floor(Math.random() * 10),
      num2: Math.floor(Math.random() * 10),
      userAnswer: "",
      errorMessage: "",
    };
  },
  methods: {
    validateAnswer() {
      if (parseInt(this.userAnswer) === this.num1 + this.num2) {
        this.errorMessage = "";
        this.onSuccess(); // Chama o método do pai quando validado
      } else {
        this.errorMessage = "Resposta incorreta. Tente novamente.";
      }
    },
  },
};
</script>
<style scoped>
.math-validation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: #fff;
  font-family: "Arial", sans-serif;
}

.validation-container {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.equation {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.answer-input {
  width: 120px;
  padding: 10px 15px;
  font-size: 1.2rem;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.answer-input::placeholder {
  color: #aaa;
  font-style: italic;
}

.answer-input:focus {
  border: 2px solid #6a11cb;
  outline: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: #f9f9f9;
}

.validate-button {
  background-color: #6a11cb;
  color: #fff;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.validate-button:hover {
  background-color: #2575fc;
}

.error {
  color: #f44336;
  font-weight: bold;
  margin-top: 20px;
}
</style>
